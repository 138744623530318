import breakpoints from './theme/breakpoints'

// API
export const FE_HOSTNAME = process.env.NEXT_PUBLIC_FE_HOSTNAME

// ----------------------------------------------------------------------
export const FIREBASE_API = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
}

// BUGSNAG
// ----------------------------------------------------------------------
export const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
export const BUGSNAG_RELEASE_STAGE =
  process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE

// TRACKER
// ----------------------------------------------------------------------
export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID

// LAYOUT
// ----------------------------------------------------------------------
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32
}

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
}

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20
}

// CATALOG_PAGE
export const CATALOG_PAGE = {
  MAX_WIDTH_LG: breakpoints.values.sm * 0.75,
  MAX_WIDTH_XL: breakpoints.values.sm
}

// TURNSTILE
// ----------------------------------------------------------------------

export const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY

// CDN
// ----------------------------------------------------------------------
export const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL
