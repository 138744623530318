import {
  serviceDelete,
  serviceGet,
  serviceGetWithTurnstile,
  servicePatch,
  servicePost,
  servicePut
} from './_factory'

const getList = (query, token) => serviceGet('/v1/users', query, token)

const getByEmail = (email, token) => serviceGet('/v1/users', { email }, token)

const getById = (id, token) => serviceGet(`/v1/users/${id}`, undefined, token)
const getProfileById = (id, token) =>
  serviceGet(`/v1/users/${id}/profile`, undefined, token)

const postCreate = (data, token) => servicePost('/v1/users', data, token)

const postVerifyEmail = (token) =>
  servicePost('/v1/users/email-verification', undefined, token)

const patchUpdate = (id, data, token) =>
  servicePatch(`/v1/users/${id}`, data, token)

const putEmail = (id, data, token) =>
  servicePut(`/v1/users/${id}/email`, data, token)

const deleteUser = (id, token) =>
  serviceDelete(`/v1/users/${id}`, undefined, token)

//
const getCount = (query, token) => serviceGet('/v1/users/count', query, token)

const getAggregateDate = (query, token) =>
  serviceGet('/v1/users/aggregates/date', query, token)

//
const getGuestUser = (data, turnstileToken) =>
  serviceGetWithTurnstile('/v1/users/guest', data, turnstileToken)
const postGuestRegister = (data) => servicePost('/v1/users/guest', data)
const postGuestUserPassword = (data) =>
  servicePost('/v1/users/guest/change-password', data)

const userService = {
  getList,
  getByEmail,
  getById,
  getProfileById,
  //
  postCreate,
  postVerifyEmail,
  patchUpdate,
  putEmail,
  deleteUser,
  //
  getCount,
  getAggregateDate,
  //
  getGuestUser,
  postGuestRegister,
  postGuestUserPassword
}

export default userService
